<template>
  <v-navigation-drawer
    app
    :mini-variant="isMiniVariant"
    class="pt-4"
    color="backgroundColorPrimary"
    style="border-right: 1px solid #c1edfe !important"
  >
    <v-toolbar-title
      class="font-weight-bold mb-9 pl-2 cursor-pointer"
      @click="$emit('mini-variant')"
    >
      {{ isMiniVariant ? "OM" : "Opinion Matters" }}
    </v-toolbar-title>

    <v-list>
      <v-list-item-group color="primary">
        <v-list-item
          v-for="header in headerLinks"
          :key="header.name"
          class="cursor-pointer"
          :to="header.path"
        >
          <v-list-item-icon>
            <v-icon>{{ header.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ header.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>

    <template v-slot:append>
      <v-icon class="ml-3 mb-2">mdi-cog</v-icon>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "TheNavbarDrawer",
  props: {
    isMiniVariant: {
      type: Boolean,
      default: false,
    },
    headerLinks: {
      type: Array,
      required: true,
    },
  },
};
</script>
