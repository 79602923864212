<template>
  <v-card color="#FCFCFC">
    <v-card-title class="text-title">Detected Keywords</v-card-title>

    <v-card-subtitle class="thai-font text-subtitle">
      ข้อความที่ถูกพบ
    </v-card-subtitle>

    <v-card-text>
      <v-chip
        v-for="keyword in Object.keys(displayedProductStats)"
        :key="keyword"
        class="mr-3 mb-3 titleColor--text thai-font"
        :color="value === keyword ? 'primary' : 'lightPrimary'"
        :outlined="value === keyword"
        @click="$emit('input', value !== keyword ? keyword : null)"
      >
        {{ keyword }} {{ displayedProductStats[keyword] }}
      </v-chip>

      <v-chip
        v-if="
          displayedProductStatsLength < displayedCount &&
          displayedProductStatsLength > 40
        "
        class="ma-1 white--text"
        style="font-size: 20px"
        color="primary"
        :ripple="false"
        @click="displayedCount = 40"
      >
        Collapse All -
      </v-chip>

      <div v-else>
        <v-chip
          class="ma-1 white--text"
          style="font-size: 20px"
          color="primary"
          :ripple="false"
          @click="displayedCount += 40"
        >
          See More +
        </v-chip>

        <v-chip
          v-if="displayedCount >= 80"
          class="ma-1 white--text"
          style="font-size: 20px"
          color="primary"
          :ripple="false"
          @click="displayedCount -= 40"
        >
          Show Less -
        </v-chip>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { deepcopy, getLimitedData } from "@/utils";

export default {
  name: "DetectedKeywordsCard",
  props: {
    data: {
      type: Object,
    },
    value: {
      type: String,
    },
  },
  data() {
    return {
      isSeeMore: false,
      displayedCount: 40,
      selectedKeyword: null,
    };
  },
  computed: {
    displayedProductStatsLength() {
      return Object.keys(this.displayedProductStats).length;
    },
    displayedProductStats() {
      let copyData = deepcopy(this.data);
      return getLimitedData(copyData, this.displayedCount);
    },
  },
  methods: {},
};
</script>
