<template>
  <v-app-bar app flat color="white">
    <v-row no-gutters>
      <v-col>
        <slot />
      </v-col>

      <div class="ml-4">
        <v-menu offset-y>
          <template #activator="{ on, attrs }">
            <v-avatar
              color="grey lighten-1"
              size="36"
              v-on="on"
              v-bind="attrs"
            ></v-avatar>
          </template>
          <v-list>
            <v-list-item @click="logout()">Log out</v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-row>
  </v-app-bar>
</template>
<script>
export default {
  name: "VueAppBar",
  methods: {
    logout() {
      localStorage.clear();
      this.$router.push({ name: "auth" });
    },
  },
};
</script>
