<template>
  <v-card color="#FCFCFC" class="box-shadow rounded-xl">
    <v-card-title class="text-title">Opinions</v-card-title>

    <v-card-subtitle class="thai-font text-subtitle"
      >ความคิดเห็น</v-card-subtitle
    >

    <v-card-text>
      <v-list max-height="465px" class="overflow-y-auto">
        <v-list-item
          v-for="item in selectedKeyword ? dataToDisplay : data"
          :key="item._id"
          three-line
          class="border-bottom"
        >
          <v-list-item-content>
            <v-list-item-title>
              <span class="font-weight-bold"> Comment</span>

              <span class="subtitleColor--text text-caption">
                11/20/23 I 9:24
              </span>
            </v-list-item-title>

            <v-list-item-subtitle>
              <v-row>
                <v-col cols="7" class="thai-font text-subtitle">
                  {{ item.sentence }}
                </v-col>

                <v-col>
                  <v-chip
                    v-for="item in Object.keys(item.key_phrases)"
                    :key="item"
                    color="lightPrimary"
                    class="mr-2 mb-2 thai-font text-subtitle"
                    :class="{ 'primary--text': item === selectedKeyword }"
                  >
                    {{ item }}
                  </v-chip>
                </v-col>
              </v-row>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "OptionsCard",
  props: {
    data: {
      type: Array,
    },
    selectedKeyword: {
      type: String,
    },
  },
  computed: {
    dataToDisplay() {
      return this.data.filter((item) =>
        Object.keys(item.key_phrases).includes(this.selectedKeyword)
      );
    },
  },
};
</script>
<style scoped>
.border-bottom {
  border-bottom: 2px solid #d9d9d9;
}
</style>
