<template>
  <component
    :is="activeComponent"
    @next="gotoNextComponent"
    @close="$emit('close')"
    :data="data"
    @success="$emit('success')"
  ></component>
</template>

<script>
import createNewDocumentDialog from "@/components/project-detail/CreateNewDocumentDialog.vue";
import ProjectSummarizationReviewDialog from "@/components/project-detail/ProjectSummarizationReviewDialog.vue";
export default {
  name: "CreateNewDocumentFlow",
  components: { createNewDocumentDialog, ProjectSummarizationReviewDialog },
  data() {
    return {
      activeComponent: createNewDocumentDialog,
      selectedColumnsData: [],
      data: {},
    };
  },
  methods: {
    gotoNextComponent($event) {
      this.data = $event;
      this.activeComponent = ProjectSummarizationReviewDialog;
    },
  },
};
</script>
