<template>
  <div>
    <v-skeleton-loader
      v-if="isLoading"
      type="image"
      class="mt-4"
    ></v-skeleton-loader>

    <v-card v-else-if="data" flat color="#FCFCFC">
      <v-card-title class="text-title">Sentiments</v-card-title>

      <v-card-subtitle class="text-subtitle thai-font">
        ความพึงพอใจ
      </v-card-subtitle>

      <LineChart
        class="ml-n4 overflow-hidden"
        :chart-options="chartOptions"
        :chart-series="chartSeries"
        :height="150"
      />

      <div class="d-flex justify-center py-0">
        <span
          v-for="item in legends"
          :key="item.name"
          class="text-capitalize subtitleColor--text mx-2 d-flex align-center"
          style="margin-right: 8px; margin-left: 8px"
        >
          <v-icon size="16px" class="mr-1" :color="item.color">
            mdi-circle
          </v-icon>

          {{ item.name }}
        </span>
      </div>
    </v-card>
  </div>
</template>

<script>
import LineChart from "@/components/chart/DataChart.vue";

export default {
  name: "SentimentCard",
  components: { LineChart },
  props: {
    data: {
      type: Object,
    },
    isLoading: { type: Boolean },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: "bar",
          stacked: true,
          stackType: "100%",
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            if (val >= 10) {
              return val.toFixed(2) + "%";
            } else {
              return "";
            }
          },
          style: {
            colors: ["#1c1c1c"],
            fontSize: "20px",
            fontFamily: "Inter, sans-serif",
            fontWeight: "600",
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        stroke: {
          width: 0,
        },
        xaxis: {
          show: false,
          labels: {
            show: false, // Hide x-axis labels
          },
        },
        yaxis: {
          show: false,
          labels: {
            show: false,
          },
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        legend: {
          show: false,
        },
      },
      type: "bar",
      legends: [
        { name: "Positive", color: "#3DC4FA" },
        { name: "Neutral", color: "#F2D854" },
        { name: "Negative", color: "#E01D2C" },
      ],
    };
  },
  computed: {
    chartSeries() {
      const sentiment =
        this.data.results.sentimentAnalysisResponseData.data.map(
          (item) => item.pred_sentiment
        );
      let counts = {
        positive: 0,
        neutral: 0,
        negative: 0,
      };
      let series = [
        {
          name: "Positive",
          data: [0],
          color: "#3DC4FA",
        },
        {
          name: "Neutral",
          data: [0],
          color: "#F2D854",
        },
        {
          name: "Negative",
          data: [0],
          color: "#E01D2C",
        },
      ];
      for (let i = 0; i < sentiment.length; i++) {
        if (sentiment[i] === "Positive") {
          counts.positive++;
        } else if (sentiment[i] === "Neutral") {
          counts.neutral++;
        } else if (sentiment[i] === "Negative") {
          counts.negative++;
        }
      }
      series[0].data = [counts.positive];
      series[1].data = [counts.neutral];
      series[2].data = [counts.negative];
      return series;
    },
  },
};
</script>
