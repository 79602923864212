<template>
  <v-card class="rounded-xl box-shadow" height="445">
    <v-form ref="form" class="fill-height">
      <v-row
        no-gutters
        justify="center"
        align="center"
        class="fill-height py-8"
      >
        <v-col
          cols="12"
          class="text-h4 font-weight-medium d-flex align-center justify-center"
        >
          Create Your Project
        </v-col>

        <v-col cols="9">
          <v-text-field
            v-model="formValues.projectName"
            rounded
            outlined
            label="Project Name"
            single-line
            :rules="[
              () => !!formValues.projectName || 'This field is required',
            ]"
          >
          </v-text-field>

          <div class="d-flex justify-center align-center">
            <v-file-input
              ref="fileInput"
              outlined
              rounded
              placeholder="Upload your logo/icon picture (Support file JPG/PNG) *Optional"
              class="mr-3"
              clear-icon="mdi-close-circle"
              clearable
              show-size
              prepend-icon=""
              accept="image/jpeg,image/png,image/webp"
              @change="setImageFile"
            >
            </v-file-input>

            <v-btn icon class="newAccent" large @click="openFileInput">
              <v-icon color="white" size="24">mdi-image-outline </v-icon>
            </v-btn>
          </div>
        </v-col>

        <v-col cols="6">
          <div class="d-flex justify-space-between">
            <v-btn
              width="200"
              class="text-capitalize box-shadow-bottom text-h6 px-16 py-7 is-rounded"
              color="primary"
              :loading="isLoading"
              @click="createProject()"
            >
              Next
            </v-btn>

            <v-btn
              width="200"
              class="is-rounded px-14 py-7 primary--text text-capitalize box-shadow-bottom text-h6"
              color="white"
              @click="$emit('close')"
            >
              Cancel
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import projectApi from "@/api/project";

export default {
  name: "CreateProjectDialog",
  data() {
    return {
      isOpenFileInput: false,
      isLoading: false,
      formValues: {
        projectName: "",
        logo: null,
      },
    };
  },

  methods: {
    openFileInput() {
      this.$refs.fileInput.$refs.input.click();
    },
    async createProject() {
      await this.$refs.form.validate();
      if (!this.$refs.form.validate()) {
        return;
      }
      this.isLoading = true;
      const formData = new FormData();
      formData.append("projectName", this.formValues.projectName);
      formData.append("logo", this.formValues.logo);
      await this.$http
        .post(projectApi.project, formData)
        .then(this.handleSuccess)
        .catch(this.handleError);
    },
    handleSuccess() {
      this.isLoading = false;
      this.setSnackBar({
        text: "Successfully Created",
        color: "green",
        display: true,
      });
      this.$emit("success");
      this.$emit("close");
    },
    handleError(error) {
      this.isLoading = false;
      this.setSnackBar({
        text: error.response.data.message,
        color: "red",
        display: true,
      });
      this.$emit("close");
    },
    setImageFile(file) {
      this.formValues.logo = file;
    },
  },
};
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #3dc4fa !important;
}
</style>
