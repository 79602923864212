import moment from "moment/moment";

export const fileToBase64 = (file) =>
  new Promise((resolve) => {
    const reader = new FileReader();
    // Read file content on file loaded event
    reader.onload = (event) => {
      resolve(event.target.result);
    };

    // Convert data to base64
    reader.readAsDataURL(file);
  });
export function deepcopy(json) {
  return JSON.parse(JSON.stringify(json));
}
export function getLimitedData(obj, displayedCount) {
  const keys = Object.keys(obj);
  return keys.slice(0, displayedCount).reduce((result, key) => {
    result[key] = obj[key];
    return result;
  }, {});
}
export function dateFormatter(date) {
  const originalDate = moment(date);
  return originalDate.format("D MMMM YYYY");
}
