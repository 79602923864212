<template>
  <v-card class="rounded-xl py-4 px-8">
    <div class="text-h6 font-weight-medium">
      <slot name="title" />
    </div>

    <div class="mt-6 mb-4">
      <slot name="description" />
    </div>

    <v-card-actions class="d-flex justify-space-around">
      <v-btn
        class="is-rounded px-14 py-6 primary--text text-capitalize box-shadow-bottom text-h6"
        color="white"
        @click="$emit('close')"
      >
        Cancel
      </v-btn>

      <v-btn
        color="red"
        class="text-capitalize text-h6 px-14 py-6 is-rounded white--text"
        @click="$emit('delete')"
        :loading="isDeleting"
      >
        Delete
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "DeleteDialogBox",
  props: {
    isDeleting: {
      type: Boolean,
    },
  },
};
</script>
