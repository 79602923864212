import { Auth0Client } from "@auth0/auth0-spa-js";

const auth0Config = {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  cookieStorage: false,
  authorizationParams: {
    redirect_uri: window.location.origin + "/auth0",
  },
};

let auth0 = null;

const initAuth0 = async () => {
  auth0 = await new Auth0Client(auth0Config);
  return auth0;
};

export { initAuth0 };
