<template>
  <v-snackbar
    v-model="snackBar.display"
    :timeout="2000"
    :color="snackBar.color"
    elevation="24"
    right
  >
    {{ snackBar.text }}
  </v-snackbar>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "VueSnackbar",
  computed: {
    ...mapGetters("common", {
      snackBar: "getSnackBar",
    }),
  },
};
</script>

<style scoped></style>
