<template>
  <div>
    <VueAppBar>
      <div class="d-flex justify-space-between">
        <div class="d-flex">
          <v-btn
            text
            class="text-capitalize subtitleColor--text"
            @click="
              $router.push({
                name: 'project-detail',
                params: { id: documentData.projectId },
              })
            "
          >
            <v-icon left large color="subtitleColor">mdi-chevron-left</v-icon>

            Back to Document List
          </v-btn>

          <v-divider vertical class="mx-1"></v-divider>

          <div class="d-flex align-center ml-4 font-weight-bold">Result</div>
        </div>
      </div>

      <span class="ml-4 subtitleColor--text text-caption" v-if="documentData">
        {{ dateFormatter(documentData.createdAt) }}
      </span>
    </VueAppBar>

    <div class="ml-4">
      <v-tabs
        v-model="tab"
        background-color="transparent"
        style="border-bottom: 1px solid #3dc4fa"
      >
        <v-tab
          v-for="(item, index) in tabs"
          class="text-capitalize"
          :class="{ 'active-class': tab === index }"
          style="border-radius: 30px 30px 0 0"
          :key="index"
        >
          {{ item }}
        </v-tab>
      </v-tabs>

      <DocumentDetailTopCard
        v-if="documentData"
        :is-loading="isLoading"
        :data="documentData"
      />

      <SentimentCard
        class="mt-4"
        :is-loading="isLoading"
        :data="documentData"
      />

      <DocumentDetailThirdRow
        class="mt-4"
        :is-loading="isLoading"
        :data="documentData"
      />
    </div>
  </div>
</template>

<script>
import SentimentCard from "@/components/document/SentimentCard.vue";
import DocumentDetailTopCard from "@/components/document/DocumentDetailTopCard.vue";
import DocumentDetailThirdRow from "@/components/document/DocumentDetailThirdRow.vue";
import projectApi from "@/api/project";
import { dateFormatter } from "@/utils";

export default {
  name: "DocumentDetail",
  components: { DocumentDetailThirdRow, DocumentDetailTopCard, SentimentCard },
  data() {
    return {
      tab: 0,
      tabs: [],
      documentData: null,
      isLoading: false,
    };
  },
  created() {
    this.fetchDocumentDetail();
  },
  methods: {
    dateFormatter,
    fetchDocumentDetail() {
      this.isLoading = true;
      this.$http
        .get(projectApi.documentDetail(this.$route.params.id))
        .then(this.handleSuccess)
        .catch(this.handleError);
    },
    handleSuccess(response) {
      this.documentData = response.data.data;
      this.tabs.push(this.documentData.documentName);
      this.isLoading = false;
    },
    handleError(error) {
      this.isLoading = false;
      this.setSnackBar({
        text: error.response.data.message,
        color: "red",
        display: true,
      });
    },
  },
};
</script>
<style scoped>
.active-class {
  background: #3dc4fa;
  color: white;
}
</style>
