<template>
  <div>
    <VueAppBar>
      <div class="d-flex justify-space-between">
        <div class="d-flex">
          <v-btn
            text
            class="text-capitalize subtitleColor--text"
            @click="$router.push({ name: 'dashboard' })"
          >
            <v-icon left large color="subtitleColor">mdi-chevron-left</v-icon>

            Back to Projects
          </v-btn>

          <v-divider vertical class="mx-1"></v-divider>

          <div class="d-flex align-center ml-4 font-weight-bold">
            Document List
          </div>
        </div>

        <v-btn
          outlined
          rounded
          color="newAccent"
          @click="isOpenCreateNewDocument = true"
        >
          <span class="titleColor--text text-capitalize">
            Create New Document
          </span>
          <v-icon right color="titleColor">mdi-plus</v-icon>
        </v-btn>
      </div>
    </VueAppBar>

    <v-row
      class="box-shadow-bottom rounded-xl pa-3 mb-7"
      no-gutters
      justify="space-between"
    >
      <v-col cols="1" class="text-center"> Status </v-col>

      <v-col cols="7" class="text-center"> Document Name </v-col>

      <v-col cols="1" class="text-center"> Language </v-col>

      <v-col cols="1" class="text-center"> Date/Time </v-col>

      <v-col cols="1" class="text-center"> Results </v-col>

      <div class="text-right d-flex align-center px-5">
        <v-icon>mdi-delete-outline</v-icon>
      </div>
    </v-row>

    <template v-if="isLoading">
      <v-skeleton-loader
        v-for="item in 4"
        :key="item"
        class="mx-auto box-shadow rounded-xl my-8 py-8"
        min-width="300"
        type="table-heading"
      />
    </template>

    <v-row
      v-else
      v-for="document in documentList"
      :key="document.id"
      no-gutters
      class="box-shadow-bottom rounded-xl px-3 py-9 mb-4"
      justify="space-between"
      align="center"
    >
      <v-col
        cols="1"
        class="text-center d-flex align-center justify-center text-capitalize"
      >
        <v-btn
          icon
          class="pa-0 mr-2"
          :class="colorMapper[document.status].bgColor"
          x-small
        >
          <v-icon :color="colorMapper[document.status].color" small>
            mdi-circle
          </v-icon>
        </v-btn>
        {{ document.status }}
      </v-col>

      <v-col cols="7" class="text-center">
        <div class="d-flex justify-center">
          {{ document.documentName }}

          <v-icon
            color="grey"
            size="20"
            class="ml-4"
            @click="renameDocument(document)"
          >
            mdi-pencil-outline
          </v-icon>
        </div>
      </v-col>

      <v-col cols="1" class="text-center">
        {{ document.language }}
      </v-col>

      <v-col cols="1" class="text-center">
        <div>{{ getDate(document.createdAt) }}</div>
        <div>{{ getTime(document.createdAt) }}</div>
      </v-col>

      <v-col cols="1" class="text-center">
        <v-chip
          class="primary subtitle-1 font-weight-bold box-shadow-bottom px-4 py-5"
          style="border-radius: 20px"
          @click="
            $router.push({
              name: 'document-detail',
              params: { id: document.id },
            })
          "
          :disabled="document.status !== 'processed'"
        >
          See More
        </v-chip>
      </v-col>

      <div class="text-right d-flex align-center px-3">
        <v-btn
          icon
          color="white"
          class="red"
          elevation="3"
          @click="(isOpenDeleteDialog = true), (documentToDelete = document)"
        >
          <v-icon>mdi-delete-outline</v-icon>
        </v-btn>
      </div>
    </v-row>

    <v-card
      v-if="!documentList.length && !isLoading"
      class="mt-4 rounded-xl box-shadow-bottom subtitleColor--text font-weight-bold d-flex flex-column align-center justify-center"
      style="font-size: 32px; height: 800px"
    >
      <v-img
        :src="require('@/assets/docs-search.svg')"
        color="subtitleColor"
        max-width="112"
        max-height="112"
      >
      </v-img>
      No Document
    </v-card>

    <v-dialog v-model="isOpenCreateNewDocument" max-width="835">
      <CreateNewDocumentFlow
        :key="isOpenCreateNewDocument"
        @close="isOpenCreateNewDocument = false"
        @success="(isOpenCreateNewDocument = false), fetchDocumentList()"
      />
    </v-dialog>

    <v-dialog v-model="isOpenDeleteDialog" max-width="500" persistent>
      <DeleteDialogBox
        :is-deleting="isDeleting"
        @close="isOpenDeleteDialog = false"
        @delete="deleteDocument()"
      >
        <template #title> Delete Document? </template>

        <template #description>
          This will delete the document permanently.<br />
          You cannot undo this action.
        </template>
      </DeleteDialogBox>
    </v-dialog>

    <template v-if="documentToEdit">
      <v-dialog v-model="isOpenEditDocumentName" max-width="367" persistent>
        <v-card class="box-shadow rounded-xl">
          <v-card-title>Name</v-card-title>

          <v-card-text>
            <v-text-field
              v-model="documentToEdit.documentName"
              :rules="[(v) => v.length <= 25 || 'Max 50 characters']"
              counter="50"
              color="primary"
            ></v-text-field>
          </v-card-text>

          <v-card-actions class="justify-end">
            <v-btn
              text
              class="text-capitalize"
              color="primary"
              :loading="isUpdating"
              @click="updateDocumentName"
            >
              Update
            </v-btn>

            <v-btn
              text
              class="text-capitalize"
              @click="isOpenEditDocumentName = false"
              >Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<script>
import CreateNewDocumentFlow from "@/components/project-detail/CreateNewDocumentFlow.vue";
import projectApi from "@/api/project";
import DeleteDialogBox from "@/components/common/DeleteDialogBox.vue";
import moment from "moment/moment";
import { deepcopy } from "@/utils";

export default {
  name: "ProjectDetailView",
  components: { DeleteDialogBox, CreateNewDocumentFlow },
  data() {
    return {
      isOpenCreateNewDocument: false,
      isOpenEditDocumentName: false,
      headers: [
        "Status",
        "Project Name",
        "Language",
        "Date/Time",
        "Price",
        "Remarks",
      ],
      documentList: [],
      isLoading: false,
      isOpenDeleteDialog: false,
      isDeleting: false,
      documentToDelete: null,
      documentToEdit: null,
      isUpdating: false,
      colorMapper: {
        processed: { color: "#7FCC1D", bgColor: "green-light" },
        processing: { color: "newAccent", bgColor: "yellow-light" },
        failed: { color: "#E01D2C", bgColor: "red-light" },
      },
    };
  },
  created() {
    this.fetchDocumentList();
  },
  methods: {
    renameDocument(documentName) {
      this.documentToEdit = deepcopy(documentName);
      this.isOpenEditDocumentName = true;
    },
    updateDocumentName() {
      this.isUpdating = true;
      this.$http
        .patch(projectApi.documentDetail(this.documentToEdit.id), {
          name: this.documentToEdit.documentName,
        })
        .then(() => {
          this.isUpdating = false;
          this.isOpenEditDocumentName = false;
          this.setSnackBar({
            text: "Successfully updated document name",
            color: "green",
            display: true,
          });
          this.fetchDocumentList();
        })
        .catch((error) => {
          this.isUpdating = false;
          this.setSnackBar({
            text: error.response.data.message,
            color: "red",
            display: true,
          });
        });
    },
    getTime(date) {
      const originalDate = moment(date);
      return originalDate.format("h:mm:ss A");
    },
    getDate(date) {
      const originalDate = moment(date);
      return originalDate.format("YYYY-MM-DD");
    },
    async fetchDocumentList() {
      this.isLoading = true;
      await this.$http
        .get(projectApi.documentList(this.$route.params.id))
        .then(this.handleSuccess)
        .catch(this.handleError);
    },
    handleSuccess(response) {
      this.documentList = response.data.data.reverse();
      this.isLoading = false;
    },
    handleError(error) {
      this.isLoading = false;
      this.setSnackBar({
        text: error.response.data.message,
        color: "red",
        display: true,
      });
    },
    deleteDocument() {
      this.isDeleting = true;
      this.$http
        .delete(
          projectApi.deleteDocument(
            this.documentToDelete.projectId,
            this.documentToDelete.id
          )
        )
        .then(this.handleDeleteSuccess)
        .catch(this.handleDeleteError);
    },
    handleDeleteSuccess(response) {
      this.isDeleting = false;
      this.isOpenDeleteDialog = false;
      this.fetchDocumentList();
      this.setSnackBar({
        text: response.data.message,
        color: "green",
        display: true,
      });
    },
    handleDeleteError(error) {
      this.isDeleting = false;
      this.setSnackBar({
        text: error.response.data.message,
        color: "red",
        display: true,
      });
    },
  },
};
</script>

<style>
.v-divider {
  background: #606060;
  max-width: 2px;
}
.box-shadow-bottom {
  box-shadow: 2px 2px 6px 0px rgba(61, 196, 250, 0.3) !important;
}

.yellow-light {
  background: #fcefb1;
}
.green-light {
  background: #cbeaa4;
}

.red-light {
  background: #e3a5aa;
}

.v-text-field > .v-input__control > .v-input__slot::before {
  border-color: #bbecff !important;
}
</style>
