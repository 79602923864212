<template>
  <div class="mt-4">
    <v-skeleton-loader v-if="isLoading" type="article"></v-skeleton-loader>

    <v-card class="box-shadow rounded-xl" color="#FCFCFC">
      <v-card-title class="text-title">
        Document Name : {{ data.documentName }}
      </v-card-title>

      <v-card-subtitle class="text-capitalize text-subtitle">
        Status : {{ data.status }}
      </v-card-subtitle>

      <v-spacer />

      <v-card-text class="titleColor--text font-weight-bold">
        Total Sentences :
        {{ data.results.contentTaggingResponseData.data.length }}<br />
        Total Keywords : {{ totalKeywords }}
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "DocumentDetailTopCard",
  props: {
    isLoading: { type: Boolean },
    data: { type: Object },
  },
  computed: {
    totalKeywords() {
      let count = 0;
      this.data.results.contentTaggingResponseData.data[0].forEach((item) => {
        count += Object.keys(item.key_phrases).length;
      });
      return count;
    },
  },
};
</script>
