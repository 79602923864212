<script>
import VueApexCharts from "vue-apexcharts";
export default {
  name: "DataChart",
  components: {
    VueApexCharts,
  },
  props: ["chartSeries", "chartOptions", "type", "height"],
};
</script>

<template>
  <VueApexCharts
    :options="chartOptions"
    :series="chartSeries"
    :type="type"
    :height="height"
  />
</template>

<style scoped></style>
