<template>
  <v-row>
    <v-col cols="8">
      <v-skeleton-loader
        v-if="isLoading"
        type="article"
        class="mt-4"
        height="180"
      ></v-skeleton-loader>

      <DetectedKeywordsCard
        v-else-if="data"
        v-model="selectedKeyword"
        class="box-shadow rounded-xl"
        :data="data.results.contentTaggingResponseData.data[1]"
        @selected-keyword="selectedKeyword = $event"
      />

      <v-skeleton-loader
        v-if="isLoading"
        type="article"
        class="mt-4"
        height="180"
      ></v-skeleton-loader>

      <OptionsCard
        v-else-if="data"
        class="mt-4"
        :data="data.results.contentTaggingResponseData.data[0]"
        :selected-keyword="selectedKeyword"
      ></OptionsCard>
    </v-col>

    <v-col cols="4">
      <v-skeleton-loader
        v-if="isLoading"
        type="card"
        class="mt-4"
      ></v-skeleton-loader>

      <TopicModelingCard
        v-else-if="data"
        class="box-shadow rounded-xl fill-height"
        :data="data.results.topicModelingResponseData.data"
      />
    </v-col>
  </v-row>
</template>

<script>
import DetectedKeywordsCard from "@/components/document/DetectedKeywordsCard.vue";
import TopicModelingCard from "@/components/document/TopicModelingCard.vue";
import OptionsCard from "@/components/document/OptionsCard.vue";

export default {
  name: "DocumentDetailThirdRow",
  components: { OptionsCard, TopicModelingCard, DetectedKeywordsCard },
  props: {
    isLoading: {
      type: Boolean,
    },
    data: {
      type: Object,
    },
  },
  data() {
    return {
      selectedKeyword: null,
    };
  },
};
</script>
