import Vue from "vue";
import { mapMutations } from "vuex";
import VueAppBar from "@/components/common/VueAppBar.vue";

Vue.component("VueAppBar", VueAppBar);
Vue.mixin({
  methods: {
    ...mapMutations("common", {
      setSnackBar: "setSnackBar",
    }),
  },
});
