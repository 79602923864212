<template>
  <v-card color="#FCFCFC">
    <v-card-title class="text-title">Topic Modeling</v-card-title>

    <v-card-subtitle class="thai-font text-subtitle">
      ข้อความที่ถูกพูดถึงมากที่สุดในแต่ละหัวข้อ
    </v-card-subtitle>

    <v-card-text>
      <div class="d-flex justify-end">
        <v-btn
          class="text-capitalize"
          depressed
          @click="isSeeData = !isSeeData"
        >
          {{ isSeeData ? "See chart" : "See Data" }}
        </v-btn>
      </div>

      <BarChart
        v-if="!isSeeData"
        :chart-options="chartOptionsData"
        :chart-series="seriesData"
        :height="230"
      ></BarChart>

      <div v-else class="ml-6 mt-9">
        <div v-for="label in chartOptionsData.labels" :key="label" class="mb-4">
          <strong class="black--text">{{ label }} :</strong>
          {{ data[1].Keywords[label.split(" ")[1]] }}
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import BarChart from "@/components/chart/DataChart.vue";

export default {
  name: "TopicModelingCard",
  components: { BarChart },
  props: {
    data: {
      type: Array,
    },
  },
  data() {
    return {
      isSeeData: false,
    };
  },
  computed: {
    chartOptionsData() {
      let chartOptions = {
        chart: {
          type: "bar",
          toolbar: {
            show: false,
          },
        },

        plotOptions: {
          bar: {
            // borderRadius: 4,
            horizontal: true,
            distributed: true,
            dataLabels: {
              position: "top",
            },
          },
        },
        legend: {
          show: false,
        },
        colors: ["#3DC4FA", "#EFCA13", "#B83ABB", "#FF5757", "#7FCC1D"],

        dataLabels: {
          enabled: true,
          offsetX: -10,
          // formatter: function (val) {
          //   if (val > 2) {
          //     return val;
          //   } else {
          //     return "";
          //   }
          // },
          style: {
            fontSize: "16px",
            colors: ["#1C1C1C"],
            fontWeight: 600,
          },
        },
        xaxis: {
          categories: [],
          labels: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            offsetX: 10,
            style: {
              colors: ["#1C1C1C"],
              fontSize: "16px",
              fontWeight: 600,
              backgroundColor: "red",
              marginLeft: "100px",
            },
          },
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        labels: [],
      };
      chartOptions.labels = Object.keys(this.data[0]).filter(
        (item) => item !== "Topic -1"
      );
      return chartOptions;
    },
    seriesData() {
      let series = [
        {
          data: [],
        },
      ];
      this.chartOptionsData.labels.forEach((label) =>
        // series[0].data.push(
        //     this.data[1].Keywords[label.split(" ")[1]].split(", ").length
        // )
        series[0].data.push(this.data[0][label])
      );
      return series;
    },
  },
};
</script>
