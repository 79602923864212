<template>
  <v-card class="rounded-xl box-shadow">
    <v-row justify="center" no-gutters>
      <v-col
        cols="12"
        class="my-8 text-h4 font-weight-medium d-flex align-center justify-center"
      >
        Project Summarization Review
      </v-col>

      <v-col cols="8" class="d-flex">
        <v-col cols="4" class="font-weight-bold">Project</v-col>
        <v-col cols="8">{{ formValues.documentName }}</v-col>
      </v-col>

      <v-col cols="8" class="d-flex">
        <v-col cols="4" class="font-weight-bold">Uplodaed File</v-col>
        <v-col cols="8">{{ formValues.name }}</v-col>
      </v-col>

      <v-col cols="8" class="d-flex">
        <v-col cols="4" class="font-weight-bold">Selected Columns</v-col>
        <v-col cols="8">
          <v-chip class="mr-2" color="primary">
            {{ formValues.selectedColumn }}
          </v-chip>
          <!--          <template v-for="(column, index) in formValues.selectedColumn">-->
          <!--            <v-chip v-if="index < 2" :key="column" class="mr-2" color="primary">-->
          <!--              {{ formValues.selectedColumn }}-->
          <!--            </v-chip>-->
          <!--          </template>-->

          <!--          <span-->
          <!--            v-if="formValues.selectedColumn.length > 2"-->
          <!--            class="primary&#45;&#45;text"-->
          <!--          >-->
          <!--            + {{ formValues.selectedColumn.slice(2).length }}-->
          <!--          </span>-->
        </v-col>
      </v-col>

      <v-col cols="8" class="d-flex">
        <v-col cols="4" class="font-weight-bold">Language</v-col>
        <v-col cols="8">{{ formValues.language }}</v-col>
      </v-col>

      <v-col cols="8" class="d-flex">
        <v-col cols="4" class="font-weight-bold">Comments</v-col>
        <v-col cols="8">{{ formValues.nrofRows }}</v-col>
      </v-col>

      <v-col cols="8" class="d-flex">
        <v-col cols="4" class="font-weight-bold">Payment/Request</v-col>
        <v-col cols="8"> {{ formValues.nrofRows * 3 }} THB </v-col>
      </v-col>

      <v-col cols="6" class="mb-8 mt-4">
        <div class="d-flex justify-space-between">
          <v-btn
            width="200"
            class="text-capitalize box-shadow-bottom text-h6 px-16 py-7 is-rounded"
            color="primary"
            :loading="isLoading"
            @click="createDocument"
          >
            Confirm
          </v-btn>

          <v-btn
            width="200"
            class="is-rounded px-14 py-7 primary--text text-capitalize box-shadow-bottom text-h6"
            color="white"
            @click="$emit('close')"
          >
            Cancel
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import createNewDocument from "@/api/createNewDocument";

export default {
  name: "ProjectSummarizationReviewDialog",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formValues: this.data,
      isLoading: false,
    };
  },
  methods: {
    createDocument() {
      this.isLoading = true;

      const params = {
        ...this.formValues,
        projectId: this.$route.params.id,
      };
      delete params.file;
      delete params.message;
      delete params.name;
      params.selectedColumn = [params.selectedColumn];
      params.price = this.formValues.nrofRows * 3;

      this.$http
        .post(createNewDocument.createDocument(this.$route.params.id), params)
        .then(this.handleSuccess)
        .catch(this.handleError);
    },
    handleSuccess() {
      this.isLoading = false;
      this.$emit("success");
      this.setSnackBar({
        text: "Successfully Created",
        color: "green",
        display: true,
      });
      this.$emit("close");
    },
    handleError(error) {
      this.isLoading = false;
      this.setSnackBar({
        text: error.response.data.message,
        color: "red",
        display: true,
      });
    },
  },
};
</script>
