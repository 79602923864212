<template>
  <div>
    <TheNavbarDrawer
      :is-mini-variant="isMiniVariant"
      :header-links="headerLinks"
      @mini-variant="isMiniVariant = !isMiniVariant"
    ></TheNavbarDrawer>

    <v-main class="ma-4">
      <router-view />
    </v-main>
  </div>
</template>

<script>
import TheNavbarDrawer from "@/components/Layouts/TheNavbarDrawer.vue";

export default {
  name: "DefaultPage",
  components: { TheNavbarDrawer },
  data() {
    return {
      isDrawerOpen: true,
      headerLinks: [
        {
          name: "Dashboard",
          icon: "mdi-view-dashboard",
          path: "/dashboard",
        },
        {
          name: "Credit",
          icon: "mdi-credit-card-outline",
          path: "/credit",
        },
        {
          name: "History",
          icon: "mdi-history",
          path: "/history",
        },
      ],
      isAuthenticated: false,
      user: null,
      idTokenClaims: null,
      token: null,
      isMiniVariant: true,
    };
  },
};
</script>

<style scoped></style>
