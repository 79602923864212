<template>
  <div class="white fill-height d-flex justify-center align-center">
    <v-card color="white" flat class="rounded-0" style="width: 100%">
      <v-card-title class="text-title mb-2"> Hello! </v-card-title>

      <v-card-subtitle class="text-subtitle">
        Let’s Sondhana Begins
      </v-card-subtitle>

      <v-card-text>
        <v-btn
          large
          block
          depressed
          class="primary py-6 px-2"
          style="border-radius: 28px"
        >
          <div class="d-flex align-center justify-center" style="width: 100%">
            <v-avatar size="35" class="logo">
              <v-img :src="require('@/assets/google.png')" max-width="35" />
            </v-avatar>

            <div
              @click="googleAuth()"
              class="white--text font-weight-bold text-h6 text-capitalize cursor-pointer"
            >
              Sign in with Google
            </div>
          </div>
        </v-btn>

        <div class="d-flex align-center mt-4">
          <v-divider class="custom-divider" />
          <div class="mx-3 grey--text text--darken-2">
            Or, sign in with your email
          </div>
          <v-divider class="custom-divider" />
        </div>

        <v-text-field
          v-model="formValues.email"
          class="pb-3 mt-8"
          rounded
          placeholder="Email"
          hide-details
          style="border: 1px solid #3dc4fa"
        ></v-text-field>

        <v-text-field
          v-model="formValues.password"
          rounded
          placeholder="Password"
          hide-details
          class="pb-3 mt-6"
          style="border: 1px solid #3dc4fa"
          :type="isVisible ? 'text' : 'password'"
          @keyup.enter="login"
        >
          <template #append>
            <v-icon @click="isVisible = !isVisible">
              {{ isVisible ? "mdi-eye-outline" : "mdi-eye-off-outline" }}
            </v-icon>
          </template>
        </v-text-field>

        <div class="d-flex align-center justify-space-between">
          <v-checkbox v-model="isKeepMeSignIn" :ripple="false">
            <template #label>
              <span class="text-body-2">Keep me sign in</span>
            </template>
          </v-checkbox>

          <v-btn text color="primary " class="text-capitalize" :ripple="false">
            Forgot login password?
          </v-btn>
        </div>

        <v-btn
          large
          depressed
          block
          class="primary text-capitalize font-weight-bold text-h6"
          style="border-radius: 24px"
          @click="login"
        >
          Login
        </v-btn>

        <div
          class="text-capitalize mt-4 text-h6 subtitleColor--text d-flex align-center justify-center cursor-pointer"
          @click="$emit('next-step')"
        >
          Sign Up
          <v-icon right large color="subtitleColor">mdi-chevron-right</v-icon>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import authApi from "@/api/auth";
const defaultFormValues = {
  email: "",
  password: "",
};
export default {
  name: "LoginCard",
  data() {
    return {
      isVisible: false,
      isKeepMeSignIn: false,
      formValues: { ...defaultFormValues },
    };
  },
  methods: {
    async googleAuth() {
      await this.$auth0.loginWithRedirect();
    },
    login() {
      const payload = this.formValues;
      this.$http
        .post(authApi.login, payload)
        .then(this.handleSuccess)
        .catch(this.handleError);
    },
    handleSuccess(response) {
      const token = response.data.token;
      localStorage.setItem("access", token);
      const now = new Date().getTime();
      localStorage.setItem("setupTime", now.toString());
      this.resetFormValues();
      this.setSnackBar({
        text: "Successfully Logged In",
        color: "green",
        display: true,
      });
      this.$router.push("/default");
    },
    resetFormValues() {
      this.formValues = { ...defaultFormValues };
    },
    handleError(error) {
      this.setSnackBar({
        text: error.response.data.message,
        color: "red",
        display: true,
      });
    },
  },
};
</script>

<style scoped>
.logo {
  position: absolute;
  left: 0;
}
.custom-divider {
  background: #bbecff;
}
</style>
