<template>
  <div class="fill-height">
    <v-overlay color="#d3d3d3">
      <div class="text-center">
        <v-progress-circular
          indeterminate
          color="primary"
          :size="60"
        ></v-progress-circular>
        <div class="text-h6 grey--text lighten-1 mt-8">
          Verifying please wait ....
        </div>
      </div>
    </v-overlay>
  </div>
</template>
<script>
import authApi from "@/api/auth";

export default {
  name: "AuthToken",
  data() {
    return {
      token: null,
      authToken: null,
      expirationDate: null,
    };
  },
  async created() {
    await this.$auth0.getTokenSilently();
    const { __raw, exp } = await this.$auth0.getIdTokenClaims();
    if (__raw && exp) {
      this.authToken = __raw;
      this.expirationDate = exp;

      this.postAuthToken();
    }
  },
  methods: {
    postAuthToken() {
      console.log("authtoken", this.authToken);
      this.$http
        .get(authApi.profile, {
          headers: { Authorization: `Bearer ${this.authToken}` },
        })
        .then(this.storeAuthToken)
        .catch(this.handleError);
    },
    storeAuthToken() {
      this.setSnackBar({
        text: "Successfully Logged In",
        color: "green",
        display: true,
      });

      localStorage.setItem("auth-token", this.authToken);
      const formattedDate = new Date(
        this.expirationDate * 1000
      ).toLocaleString();
      localStorage.setItem("expiration-date", formattedDate);
      this.$router.push({ name: "default-page" });
    },
    handleError(error) {
      this.setSnackBar({
        text: error.response.data.message,
        color: "red",
        display: true,
      });
      this.$router.push({ name: "auth" });
    },
  },
};
</script>
