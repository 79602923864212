<template>
  <v-container>
    <v-row justify="center" align="center" no-gutters class="mb-4">
      <v-col
        cols="5"
        class="d-flex align-center subtitleColor--text text-h6 cursor-pointer"
        @click="$emit('previous-step')"
      >
        <v-icon large left color="titleColor">mdi-chevron-left</v-icon>

        Back
      </v-col>
    </v-row>

    <div class="d-flex justify-center">
      <v-img :src="require('@/assets/sodhana-icon.png')" max-width="80" />
    </div>

    <v-form class="mt-4">
      <v-row align="center" justify="center" no-gutters>
        <v-col cols="5">
          <v-text-field
            v-model="formValues.firstName"
            class="pb-3 mt-8"
            rounded
            placeholder="First Name"
            hide-details
          ></v-text-field>

          <v-text-field
            v-model="formValues.lastName"
            class="pb-3 mt-8"
            rounded
            placeholder="Last Name"
            hide-details
          ></v-text-field>

          <v-text-field
            v-model="formValues.email"
            class="pb-3 mt-8"
            rounded
            placeholder="Email Name"
            hide-details
          ></v-text-field>

          <v-text-field
            v-model="formValues.password"
            class="pb-3 mt-8"
            rounded
            placeholder="Create Password"
            :type="isPasswordVisible ? 'text' : 'password'"
            hide-details
          >
            <template #append>
              <v-icon @click="isPasswordVisible = !isPasswordVisible">
                {{
                  isPasswordVisible ? "mdi-eye-outline" : "mdi-eye-off-outline"
                }}
              </v-icon>
            </template>
          </v-text-field>

          <v-text-field
            v-model="confirmPassword"
            class="pb-3 mt-8"
            rounded
            placeholder="Confirm Password"
            :type="isConfirmPassword ? 'text' : 'password'"
            hide-details
          >
            <template #append>
              <v-icon @click="isConfirmPassword = !isConfirmPassword">
                {{
                  isConfirmPassword ? "mdi-eye-outline" : "mdi-eye-off-outline"
                }}
              </v-icon>
            </template>
          </v-text-field>

          <v-checkbox
            v-model="isCheckedTermsAndConditions"
            class="mt-10"
            :ripple="false"
          >
            <template #label>
              I agree to the
              <a class="mx-1"> Terms of Service </a> and
              <a class="ml-1">Privacy Policy</a>*
            </template>
          </v-checkbox>

          <v-btn
            color="primary"
            large
            depressed
            block
            style="border-radius: 24px"
            class="text-capitalize text-subtitle-1 font-weight-bold mt-5"
            @click="signUp"
          >
            Submit
          </v-btn>

          <div class="text-center mt-2 subtitleColor--text">
            Already have an account?<a
              class="ml-1"
              @click="$emit('previous-step')"
            >
              Log in
            </a>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import authApi from "@/api/auth";
const defaultFormValues = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
};
export default {
  name: "SignupCard",
  data() {
    return {
      isCheckedTermsAndConditions: false,
      confirmPassword: "",
      formValues: { ...defaultFormValues },
      isPasswordVisible: false,
      isConfirmPassword: false,
    };
  },
  methods: {
    signUp() {
      const payload = this.formValues;
      this.$http
        .post(authApi.signup, payload)
        .then(this.handleSuccess)
        .catch(this.handleError);
    },
    handleSuccess() {
      this.resetFormValues();
      this.setSnackBar({
        text: "Successfully Created an account",
        color: "green",
        display: true,
      });
      this.$emit("previous-step");
    },
    handleError(error) {
      this.setSnackBar({
        text: error.response.data.message,
        color: "red",
        display: true,
      });
    },
    resetFormValues() {
      this.formValues = { ...defaultFormValues };
      this.confirmPassword = "";
    },
  },
};
</script>

<style scoped>
.v-text-field {
  border: 1px solid #3dc4fa;
}
</style>
