import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#3DC4FA",
        secondary: "#853387",
        titleColor: "#1c1c1c",
        subtitleColor: "#606060",
        backgroundColorPrimary: "#FCFCFC",
        newAccent: "#EFCA13",
        lightPrimary: "#D6F1FC",
      },
    },
    typography: {
      fontFamily: "Inter, sans-serif",
    },
  },
});
