<template>
  <LogIn />
</template>

<script>
import LogIn from "@/components/auth/LogIn.vue";
export default {
  name: "LogOrRegisterView",
  components: { LogIn },
};
</script>
