<script>
import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "HomeView",
  components: { HelloWorld },
};
</script>

<template>
  <div class="red">sdfsdf <HelloWorld /></div>
</template>

<style scoped></style>
