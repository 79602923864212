<template>
  <v-card class="rounded-xl box-shadow">
    <v-row no-gutters justify="center" align="center" class="fill-height py-8">
      <v-col
        cols="12"
        class="text-h4 my-10 font-weight-medium d-flex align-center justify-center"
      >
        Create New Document
      </v-col>

      <v-col cols="9">
        <v-text-field
          v-model="formValues.documentName"
          rounded
          outlined
          label="Name"
          single-line
        >
        </v-text-field>

        <div class="d-flex justify-center align-center mb-8">
          <v-file-input
            ref="fileInput"
            outlined
            rounded
            placeholder="Input File (Suppport file EXCEL only)"
            class="mr-3"
            clear-icon="mdi-close-circle"
            clearable
            prepend-icon=""
            :accept="[
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            ]"
            @change="setFile"
            hide-details
          >
          </v-file-input>

          <v-btn icon class="newAccent" large @click="openFileInput">
            <v-icon color="white" size="24">mdi-paperclip</v-icon>
          </v-btn>
        </div>

        <v-select
          :disabled="!columns.length"
          v-model="formValues.selectedColumn"
          rounded
          outlined
          single-line
          label="Select Column for Open-ended Questions"
          append-icon="mdi-chevron-down"
          :items="columns"
          :menu-props="{ bottom: true, offsetY: true }"
          chips
          clearable
          @change="selectData"
        >
          <template #selection="{ item }">
            <v-chip
              color="primary"
              close
              @click:close="formValues.selectedColumn = null"
            >
              {{ item }}
            </v-chip>
          </template>
        </v-select>

        <v-select
          v-model="formValues.language"
          outlined
          rounded
          single-line
          append-icon="mdi-chevron-down"
          label="Language"
          :menu-props="{ bottom: true, offsetY: true }"
          :items="languageList"
        />
      </v-col>

      <v-col cols="6">
        <div class="d-flex justify-space-between">
          <v-btn
            width="200"
            class="text-capitalize box-shadow-bottom text-h6 px-16 py-7 is-rounded"
            color="primary"
            :loading="isLoading"
            @click="uploadDocument()"
          >
            Next
          </v-btn>

          <v-btn
            width="200"
            class="is-rounded px-14 py-7 primary--text text-capitalize box-shadow-bottom text-h6"
            color="white"
            @click="$emit('close')"
          >
            Cancel
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import Papa from "papaparse";
import * as XLSX from "xlsx";
import createNewDocument from "@/api/createNewDocument";

export default {
  name: "CreateNewDocumentDialog",
  data() {
    return {
      isLoading: false,
      columns: [],
      csvData: [],
      formValues: {
        documentName: "",
        file: null,
        selectedColumn: null,
        selectedColumnsData: [],
        language: null,
        name: null,
      },
      languageList: ["Thai", "English"],
    };
  },
  methods: {
    unSelectColumn(index) {
      this.formValues.selectedColumn.splice(index, 1);
      this.selectData();
    },
    openFileInput() {
      this.$refs.fileInput.$refs.input.click();
    },
    setFile(file) {
      this.formValues.file = file;
      if (this.formValues.file) {
        this.formValues.name = file.name;
        const reader = new FileReader();
        reader.onload = (e) => {
          const content = e.target.result;

          if (this.formValues.file.type === "text/csv") {
            Papa.parse(content, {
              header: true,
              complete: (result) => {
                this.columns = result.meta.fields;

                this.csvData = result.data;
              },
              error: (error) => {
                console.error("CSV parsing error:", error.message);
              },
            });
          } else {
            const workbook = XLSX.read(content, { type: "binary" });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const xlsxData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

            this.columns = xlsxData[0];

            xlsxData.shift();
            const filteredXlsxData = xlsxData.filter((item) => item.length);

            this.csvData = filteredXlsxData.map((row) =>
              this.columns.reduce(
                (obj, col, index) => ({ ...obj, [col]: row[index] }),
                {}
              )
            );
          }
        };
        reader.readAsBinaryString(this.formValues.file);
      } else {
        this.formValues.selectedColumnsData = [];
        this.formValues.selectedColumn = [];
        this.csvData = [];
        this.columns = [];
      }
    },
    async selectData() {
      if (!this.formValues.selectedColumn) {
        this.formValues.selectedColumnsData = null;
      }
      // const filteredCsvData = this.csvData.filter(
      //   (item) => Object.keys(item).length > 0
      // );
      // if (this.csvData.length) {
      //   this.formValues.selectedColumnsData = this.csvData.map((item) => {
      //     const filteredItem = {};
      //
      //     this.formValues.selectedColumn.forEach((key) => {
      //       if (key in item) {
      //         filteredItem[key] = item[key];
      //       }
      //     });
      //     return filteredItem;
      //   });
      // }
    },
    uploadDocument() {
      this.isLoading = true;
      const formData = new FormData();
      formData.append("document", this.formValues.file);
      this.$http
        .post(createNewDocument.fileUpload, formData)
        .then(this.handleSuccess)
        .catch(this.handleError);
    },
    handleSuccess(response) {
      const data = response.data;
      console.log("data", data);

      this.formValues = { ...this.formValues, ...data };
      delete this.formValues.selectedColumnsData;
      console.log("this.formValues", this.formValues);

      this.isLoading = false;
      this.setSnackBar({
        text: "Successfully Uploaded",
        color: "green",
        display: true,
      });
      this.$emit("next", this.formValues);
    },
    handleError(error) {
      this.isLoading = false;

      this.setSnackBar({
        text: error.response.data.message,
        color: "red",
        display: true,
      });
    },
  },
};
</script>
<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #3dc4fa !important;
}
</style>
