<template>
  <div>
    <VueAppBar>
      <v-toolbar-title class="font-weight-bold"> Projects </v-toolbar-title>
    </VueAppBar>

    <v-row class="pa-6">
      <template v-if="isFetching">
        <v-skeleton-loader
          v-for="item in 4"
          :key="item"
          class="mx-auto"
          min-width="300"
          type="card"
        />
      </template>

      <template v-else>
        <v-card
          class="box-shadow rounded-xl mr-4 mb-4"
          min-width="312"
          max-height="353"
        >
          <v-card-text>
            <div
              class="dashed-border d-flex justify-center align-center cursor-pointer"
              @click="isOpenCreateProjectDialog = true"
            >
              <v-icon large color="#853387">mdi-plus</v-icon>
            </div>
          </v-card-text>

          <v-card-text
            class="titleColor--text font-weight-bold text-center"
            style="font-size: 16px"
          >
            Create New Project
          </v-card-text>
        </v-card>

        <v-card
          v-for="project in projectLists"
          :key="project.id"
          class="box-shadow rounded-xl mr-4 mb-4"
          min-width="312"
          max-height="353"
        >
          <v-card-text>
            <v-img
              :src="project.logo"
              width="292"
              height="245"
              class="rounded-lg cursor-pointer"
              :gradient="
                !project.logo
                  ? 'to right top, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.3)'
                  : ''
              "
              @click="
                $router.push({
                  name: 'project-detail',
                  params: { id: project.id },
                })
              "
            />
          </v-card-text>

          <v-card-text
            class="py-0 titleColor--text font-weight-bold"
            style="font-size: 16px"
          >
            {{ project.projectName }}
          </v-card-text>
          <v-card-subtitle
            class="py-0 titleColor--text"
            style="font-size: 12px"
          >
            Documents :
            {{
              project.documents.filter(
                (document) => document.status === "processed"
              ).length
            }}
          </v-card-subtitle>
          <v-card-subtitle
            class="py-0 justify-space-between d-flex align-center"
            style="font-size: 10px"
          >
            {{ dateFormatter(project.createdAt) }}
            <v-btn
              icon
              @click="(isOpenDeleteDialog = true), (projectToDelete = project)"
            >
              <v-icon color="red">mdi-delete-outline</v-icon>
            </v-btn>
          </v-card-subtitle>
        </v-card>
      </template>
    </v-row>

    <v-dialog v-model="isOpenCreateProjectDialog" max-width="835">
      <CreateProjectDialog
        :key="isOpenCreateProjectDialog"
        @close="isOpenCreateProjectDialog = false"
        @success="fetchProjectList"
      ></CreateProjectDialog
    ></v-dialog>

    <v-dialog v-model="isOpenDeleteDialog" persistent max-width="500">
      <DeleteDialogBox
        :is-deleting="isDeleting"
        @close="isOpenDeleteDialog = false"
        @delete="deleteProject()"
      >
        <template #title> Delete {{ projectToDelete.projectName }} ? </template>

        <template #description>
          This will delete the project permanently.<br />
          You cannot undo this action.
        </template>
      </DeleteDialogBox>
    </v-dialog>
  </div>
</template>

<script>
import CreateProjectDialog from "@/components/dashboard/CreateProjectDialog.vue";
import projectApi from "@/api/project";
import DeleteDialogBox from "@/components/common/DeleteDialogBox.vue";
import { dateFormatter } from "@/utils";

export default {
  name: "DashboardView",
  components: { DeleteDialogBox, CreateProjectDialog },
  data() {
    return {
      isOpenCreateProjectDialog: false,
      projectLists: [],
      isFetching: false,
      isOpenDeleteDialog: false,
      projectToDelete: null,
      isDeleting: false,
    };
  },
  created() {
    this.fetchProjectList();
  },
  methods: {
    dateFormatter,
    fetchProjectList() {
      this.isFetching = true;
      this.$http
        .get(projectApi.project)
        .then(this.handleSuccess)
        .catch(this.handleError);
    },
    handleSuccess(response) {
      this.isFetching = false;
      this.projectLists = response.data.data.reverse();
    },
    handleError(error) {
      this.isFetching = false;
      this.setSnackBar({
        text: error.response.data.message,
        color: "red",
        display: true,
      });
    },

    deleteProject() {
      this.isDeleting = true;
      this.$http
        .delete(projectApi.projectId(this.projectToDelete.id))
        .then((response) => {
          this.isOpenDeleteDialog = false;
          this.isDeleting = false;
          this.fetchProjectList();
          this.setSnackBar({
            text: response.data.message,
            color: "green",
            display: true,
          });
        })
        .catch((error) => {
          this.isDeleting = false;
          this.setSnackBar({
            text: error.response.data.message,
            color: "red",
            display: true,
          });
        });
    },
  },
};
</script>

<style scoped>
.dashed-border {
  border: 1px dashed rgba(217, 217, 217, 0.87);
  height: 245px;
  border-radius: 8px;
}
</style>
