<template>
  <div>
    <h1 class="d-flex align-center">
      VoC powered by

      <v-img :src="require('@/assets/sodhana-logo.png')" class="ml-2"></v-img>
    </h1>

    <div class="text-h5 mt-1">
      Explore, collect, and analyze your <br />insights with us
    </div>
  </div>
</template>

<script>
export default {
  name: "PoweredBy",
};
</script>
