<template>
  <v-app>
    <router-view />

    <VueSnakeBar />
  </v-app>
</template>

<script>
import VueSnakeBar from "@/components/common/VueSnakeBar.vue";
export default {
  name: "App",
  components: { VueSnakeBar },

  data: () => ({}),
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Noto+Sans+Thai:wght@100&display=swap");
#app {
  font-family: "Inter", sans-serif !important;
}
</style>
