import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { initAuth0 } from "./plugins/auth";
import "./assets/style.scss";
import "./plugins/axios";
import "./plugins/globalComponents";
import axios from "axios";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.interceptors.request.use(function (config) {
  if (localStorage.getItem("auth-token")) {
    config.headers.Authorization = `Bearer ${localStorage.getItem(
      "auth-token"
    )}`;
  }
  return config;
});
Vue.config.productionTip = false;

const init = async () => {
  const auth = await initAuth0();
  const isAuthenticated = await auth.isAuthenticated();
  if (isAuthenticated) {
    await auth.handleRedirectCallback();
  }
  Vue.prototype.$auth0 = auth;
  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
};
init();
