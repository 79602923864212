import Vue from "vue";
import VueRouter from "vue-router";
import DefaultPage from "@/layouts/Default.vue";
import LogOrRegisterView from "@/views/LogOrRegisterView.vue";
import HomeView from "@/views/HomeView.vue";
import AuthToken from "@/components/auth/AuthToken.vue";
import DashboardView from "@/views/DashboardView.vue";
import ProjectDetailView from "@/views/ProjectDetailView.vue";
import DocumentDetail from "@/components/document/DocumentDetail.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "default-page",
    component: DefaultPage,
    redirect: "/dashboard",
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: DashboardView,
      },
      {
        path: "/credit",
        name: "credit",
        component: DashboardView,
      },
      {
        path: "/history",
        name: "history",
        component: DashboardView,
      },
      {
        path: "/dashboard/:id",
        name: "project-detail",
        component: ProjectDetailView,
      },
      {
        path: "/document/:id",
        name: "document-detail",
        component: DocumentDetail,
      },
    ],
  },
  {
    path: "/auth",
    name: "auth",
    component: LogOrRegisterView,
  },
  {
    path: "/home",
    name: "home",
    component: HomeView,
  },
  {
    path: "/auth0",
    name: "auth-token",
    component: AuthToken,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name === "auth-token") {
    next();
    return;
  }
  let currentTime = new Date();
  let expirationDate = new Date(localStorage.getItem("expiration-date"));
  if (currentTime > expirationDate) {
    localStorage.clear();
  }
  if (to.name !== "auth" && !localStorage.getItem("expiration-date")) {
    next({ name: "auth" });
  } else {
    next();
  }
});
export default router;
