<template>
  <div class="h-100vh">
    <v-stepper v-model="step" flat class="fill-height rounded-0">
      <v-stepper-items class="fill-height">
        <v-stepper-content step="1" class="fill-height px-10 bg-img1">
          <v-card
            height="95vh"
            color="transparent"
            class="d-flex flex-column justify-space-between"
          >
            <v-card-text class="fill-height">
              <v-row
                no-gutters
                justify="center"
                align="center"
                class="fill-height"
              >
                <v-col cols="6" class="text-center white--text custom-h3">
                  “Discover brand health, competitors, and consumers' needs to
                  define your positioning and new opportunities.”
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions class="justify-space-between align-end">
              <PoweredBy />

              <v-icon color="white" size="40" @click="step = 2">
                mdi-arrow-right
              </v-icon>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="2" class="px-10 fill-height bg-img2">
          <v-card
            height="95vh"
            color="transparent"
            class="d-flex flex-column justify-space-between"
          >
            <v-card-text class="fill-height">
              <v-row
                no-gutters
                justify="center"
                align="center"
                class="fill-height"
              >
                <v-col cols="6" class="text-center white--text custom-h3">
                  “Explore consumers' insights, behaviors and their topic of
                  interests in the market.”
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions class="justify-space-between align-end">
              <PoweredBy />

              <div>
                <v-icon @click="step = 1" color="white" size="40" class="mr-4">
                  mdi-arrow-left
                </v-icon>

                <v-icon @click="step = 3" color="white" size="40">
                  mdi-arrow-right
                </v-icon>
              </div>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="3" class="pa-0">
          <div class="d-flex" style="height: 100%">
            <v-row no-gutters>
              <v-col cols="8" class="px-10 bg-img3">
                <v-card
                  height="97vh"
                  color="transparent"
                  class="d-flex flex-column justify-space-between"
                  flat
                >
                  <v-card-text class="fill-height">
                    <v-row
                      no-gutters
                      justify="center"
                      align="center"
                      class="fill-height"
                    >
                      <v-col cols="8" class="text-center white--text custom-h3">
                        “Understand consumers' thought and feeling toward your
                        product to lead product development.”
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-actions class="justify-space-between align-end">
                    <PoweredBy />

                    <v-icon @click="step = 2" color="white" size="40">
                      mdi-arrow-left
                    </v-icon>
                  </v-card-actions>
                </v-card>
              </v-col>

              <v-col cols="4" style="height: 100vh">
                <LoginCard @next-step="step = 4"></LoginCard>
              </v-col>
            </v-row>
          </div>
        </v-stepper-content>

        <v-stepper-content step="4" class="fill-height white">
          <SignupCard @previous-step="step = 3" />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import LoginCard from "@/components/auth/LoginCard.vue";
import PoweredBy from "@/components/PoweredBy.vue";
import SignupCard from "@/components/auth/SignupCard.vue";

export default {
  name: "LoginOrRegister",
  components: { SignupCard, PoweredBy, LoginCard },
  data() {
    return {
      step: 1,
      bgImages: [
        "@/assets/step1-img.png",
        "@/assets/step2-img.png",
        "@/assets/step3-img.png",
      ],
    };
  },
};
</script>

<style scoped>
.h-100vh {
  height: 100vh;
}
.v-icon {
  border-radius: 100%;
  border: 3px solid white;
  padding: 5px;
}
.bg-img1 {
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.6) 100%
    ),
    url("@/assets/step1-img.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.bg-img2 {
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.6) 100%
    ),
    url("@/assets/step2-img.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.bg-img3 {
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.6) 100%
    ),
    url("@/assets/step3-img.png");
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
